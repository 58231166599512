<div #container class="video-container" [ngClass]="displayMode" (click)="showControls()">

  <video #videoAzz id="video-azz" muted="muted" playsinline="playsinline" autoplay="autoplay"></video>

  <div class="video-disabled" *ngIf="isReady"></div>

  <div class="video-overlay" [ngClass]="{'hidden': !isOverlayOn && !playerControls.isPaused}">

    <div [hidden]="showingAd" class="video-overlay-content" (dblclick)="fullScreenPressed()">

      <div class="content-top">
        <h2 *ngIf="displayMode === 'landscape'">
          <ion-icon name="chevron-back" (click)="exitPage.emit(true)"></ion-icon>
          {{ _data.name }}</h2>
      </div>

      <div class="content-middle">
        <ion-icon name="arrow-undo"
                  class="back"
                  (click)="stepBackPressed()"></ion-icon>

        <ion-icon [name]="playerControls.isPaused ? 'play' : 'pause'"
                  (click)="playPressed()">
        </ion-icon>

        <ion-icon name="arrow-redo"
                  [hidden]="_mode === 'live' && offsetCurrent === 0"
                  (click)="stepForthPressed()"></ion-icon>
      </div>

      <div class="content-bottom">

        <div class="time">
          <span>{{ currentTimeFormatted }}</span>
          <span *ngIf="playerControls.isLive && (_mode === 'live') && !playerControls.liveFinished">
          <ion-icon name="ellipse" color="danger"></ion-icon>
            {{ 'player.live' | translate }}
          </span>
          <span *ngIf="playerControls.liveFinished">{{ 'player.finished' | translate }}</span>
        </div>

        <ion-range aria-label="Progress"
                   [(ngModel)]="progressValue"
                   (ionChange)="changeProgress()"></ion-range>

        <div class="buttons-container">
          <ion-icon name="play-skip-back" (click)="goToStart()"></ion-icon>

          <!-- <ion-icon name="skip-end-fill"
                    (click)="this.goToLive()"
                    *ngIf="!playerControls.isLive && (_mode === 'live') && !playerControls.liveFinished"></ion-icon> -->

          <ion-text (click)="this.goToLive()"
                    [title]="'player.goToLiveButton' | translate"
                    *ngIf="!playerControls.isLive && (_mode === 'live') && !playerControls.liveFinished">
                    {{'player.goToLiveButton' | translate}}
          </ion-text>


          <!--<ion-text *ngIf="_mode === 'live' && !playerControls.liveFinished"
                (click)="onClickLowLatency()"
                [title]="lowLatency ? ('player.lowLatencyEnabled' | translate): 'player.lowLatencyDisabled' | translate"
                [ngStyle]="lowLatency ? {color: 'red'}: {color: 'white'}">
            {{ 'player.lowLatency' | translate }}
          </ion-text>-->

          <ion-icon [name]="(playerControls.volume === 0) ? 'volume-mute' : (playerControls.volume > 50 ?'volume-high' : 'volume-how')"
                    (click)="mutePressed()">
          </ion-icon>
          <div class="volume">
            <ion-range *ngIf="false"
                       class="volume"
                       [(ngModel)]="playerControls.volume"
                       (ionKnobMoveEnd)="changeVolume($event)"
                       step="10"
                       min="0"
                       max="100"></ion-range>
          </div>

          <div class="end">
            <ion-icon *ngIf="false"
                      name="expand"
                      (click)="fullScreenPressed()"
                      style="margin-left: auto"></ion-icon>
            <ion-icon name="browsers"
                      (click)="onPipScreenPressed()" > </ion-icon>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>
