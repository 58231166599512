import { Injectable } from '@angular/core';
import { ScreenOrientation, ScreenOrientationResult } from '@capacitor/screen-orientation';
import { BehaviorSubject, Observable } from 'rxjs';

import { DeviceService } from '@azz-life-streamer/shared';


@Injectable({
  providedIn: 'root'
})
export class MobileScreenOrientationService {

  curScreenOrientation$: Observable<string>;

  private _curScreenOrientation: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');

  constructor(private deviceService: DeviceService) {
    this.curScreenOrientation$ = this._curScreenOrientation.asObservable();
    this.initListener();
  }

  ngOnDestroy(): void {
    ScreenOrientation.removeAllListeners()
      .catch(console.error);
  }

  public setPortrait(): void {
    // console.warn('[MobileScreenOrientationService] -> setPortrait');

    // JMM: Commented to avoid orienteation.lock Error (Need to ask Susana)
    // if(this.deviceService.device?.platform !== 'web') {
    //   if(this.deviceService.device?.platform === 'ios') {
    //     ScreenOrientation.lock({ orientation: 'portrait' })
    //       .catch(console.error);
    //   } else if(this.deviceService.device?.platform === 'android') {
    //     window.screen.orientation.lock('portrait')
    //       .catch(console.error);
    //   }
    // }
  }

  public setLandscape(): void {
    // console.log('[MobileScreenOrientationService] -> setLandscape');

    // JMM: Commented to avoid orienteation.lock Error (Need to ask Susana)
    // if(this.deviceService.device?.platform !== 'web') {
    //   if(this.deviceService.device?.platform === 'ios') {
    //     ScreenOrientation.lock({ orientation: 'landscape-secondary' })
    //       .catch(console.error);
    //   } else if(this.deviceService.device?.platform === 'android') {
    //     window.screen.orientation.lock('landscape')
    //       .catch(console.error);
    //   }
    // }
  }

  public unlockScreen(): void {
    // console.warn('[MobileScreenOrientationService] -> unlockScreen');
    if(this.deviceService.device?.platform !== 'web') {
      ScreenOrientation.unlock()
        .catch(console.error);
    }
  }

  private getOrientation(orientation: ScreenOrientationResult): string {
    if (orientation.type.includes('landscape')) {
     return 'landscape';
    } else if (orientation.type.includes('portrait')) {
      return 'portrait';
    } else {
      return 'undefined';
    }
  }

  public initOrientation(): void {
    if(this.deviceService.device?.platform !== 'web') {

      // Setting orientation
      ScreenOrientation.orientation()
        .then((orientationResult: ScreenOrientationResult) => {
          this._curScreenOrientation.next(this.getOrientation(orientationResult));
        })
        .catch(console.error);
    }
  }

  private initListener(): void {
    ScreenOrientation.addListener('screenOrientationChange',(orientation: ScreenOrientationResult) => {
        if (orientation.type.includes('landscape')) {
          this._curScreenOrientation.next('landscape');
        } else if (orientation.type.includes('portrait')) {
          this._curScreenOrientation.next('portrait');
        } else {
          this._curScreenOrientation.next('undefined');
        }
      }
    );
  }

  /**
   * Only for iOS
   * Changes landscape orientation when orientation listener triggers
   *
   * @param orientation
   */
  /*public updateLandscapeType(orientation: string): void {
    // console.log('UTILS -> [updateLandscapeType] type received: ' + type);

    if(this.platform.is('ios') && type.includes('landscape')) {
      if(type === 'landscape-primary') {
        type = 'landscape-secondary';
      } else if(type === 'landscape-secondary') {
        type = 'landscape-primary';
      }
      if(this.platform.is('ios')) {
        // console.log('UTILS -> [updateLandscapeType] type to set:' + type);
        ScreenOrientation.lock({ orientation: type }).catch(console.error);
      } else {
        // console.log('UTILS -> [updateLandscapeType] portrait type: no changes');
      }
    }
  }*/
}
