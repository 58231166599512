import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { ErrorSeverity, InfoScreenMediaType, ModalDialogId } from '../../enums/utils.enum';

export interface ITabItemExtended {
  id: number;
  icon: string;
  iconApp: string;
  label: string;
  title: string;
  dirty?: boolean;
  visible?: boolean;
}

export interface IKeyName {
  key: string;
  name: string;
}

export interface IAlertDataButton {
  text: string;
  color: string;
  fill?: 'clear' | 'outline' | 'solid'; //default solid
  onDismiss?: string,
  onClickLoading?: boolean; //default false
  disabled?: boolean; //default false
  closeButton?: boolean; //default false
  handler?: (data?: any) => boolean | void | Promise<boolean | void>;
}

export interface IAlertData {
  show: boolean;
  status: AlertStatus;
  icon?: string;
  iconColor?: string;
  title?: string;
  text?: string;
  component?: string;
  componentInputs?: any;
  buttons?: IAlertDataButton[];
  closeButton: boolean; //default true
}

export interface IToastConfig {
  options: IToastOptions;
  data: IToastData;
}

export interface IToastOptions {
  placement: ToastPlacement; //default bottomRight
  animation?: boolean; // default true
  autohide?: boolean; // default true
  delay?: number; //Default 5000
}

export interface IToastDataButton {
  text: string;
  color: string;
  role?: string;
  fill?: 'clear' | 'outline' | 'solid'; //default solid
  closeButton?: boolean; //default false
  handler?: () => boolean | void | Promise<boolean | void>;
}

export interface IToastData {
  status: ToastStatus;
  icon?: string;
  iconColor?: string;
  title?: string;
  time?: string;
  closeButtonHeader?: boolean; //default false
  text?: string;
  alignText?: string; //default text-left
  buttons?: IToastDataButton[];
  closeButtonBody?: boolean; //default false
}

export interface ITheme {
  key: string;
  name: string;
  icon: string;
}

export interface IError {
  code?: number;
  text: string;
  severity?: ErrorSeverity;
  location?: string;
  display: 'none' | 'toast' | 'alert';  // whether to display error to user and where
  report: boolean;  // sends an email to team
}

/* eslint-disable @typescript-eslint/naming-convention*/
export interface IHttpResponse {
  error: number;
  message: string;
  status_code: number;
  status_text: string;
  data: any;
  code: number;
}

export interface IStatusResponse {
  statusCode?: number;
  message?: string;
  versionCode: number;
  versionName?: string;
}

export interface IResultResponse {
  type: string; // 'error', 'warn', 'info'
  message: string;
}

export interface IAzzInfoTextItem {
  text: string;
  textBullets: string[];
}

export interface IAzzInfoButton {
  text: string;
  url: string;
}

export interface IAzzInfoScreen {
  sectionIcon: string;
  mediaType: InfoScreenMediaType;
  mediaUrl: string;
  styleClass: string;
  title: string;
  textItems: IAzzInfoTextItem[];
  buttons: IAzzInfoButton[];
}

export interface IModalDialogData {
  //id: ModalDialogId;
  id: number;
  title: string;
  size?: string;   // 'sm','','lg','xl'
  subId?: number;
  info?: any;
  closeAll?: boolean;
}

export interface IContactData {
  name: string;
  email: string;
  subject: string;
  comments: string;
  version: string;
}

export type CamelToSnake<T extends string, P extends string = ''> = string extends T ? string :
  T extends `${infer C0}${infer R}` ?
    CamelToSnake<R, `${P}${C0 extends Lowercase<C0> ? '' : '_'}${Lowercase<C0>}`> : P;

export type IApi<T> = {
  [K in keyof T as CamelToSnake<Extract<K, string>>]: T[K]
};
