<section class="container-fluid profile-banner" [ngClass]="this.layoutMode">
  <div class="banner-background"
    [ngStyle]="{'background-image' : 'url(' + this.bannerImageSrc + ')'}">
    <div *ngIf="this.name !== undefined" class="img-name-container"
      [title]="'organizations.bannerTooltip' | translate" webTooltip
      data-bs-trigger="hover" data-bs-offset="[40, 8]">
      <div class="img-container" [ngStyle]="{'background-image' : 'url(' + this.profileImageSrc + ')'}">
      </div>
      <h1 class="name-chip">{{ this.name }}</h1>
    </div>
    <div *ngIf="this.name === undefined" class="img-container"
      [ngStyle]="{'background-image' : 'url(' + this.profileImageSrc + ')'}"
      [title]="'organizations.bannerTooltip' | translate" webTooltip data-bs-trigger="hover"></div>
  </div>
</section>