import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { PlayerClass } from '../../classes/player/player.class';
import { Subscription } from 'rxjs';
import { IPlayerData } from '../../interfaces/player/player.interface';
import { AlertController, IonIcon, IonRange, IonText } from '@ionic/angular/standalone';
import { AlertService } from '../../services/alert/alert.service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormatHoursTimePipe } from '../../pipes/format-hours-time/format-hours-time.pipe';
import { HttpClient } from '@angular/common/http';
import { IAlertData } from '../../interfaces/utils/utils.interface';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { browsers, chevronBack, ellipse, expand, play, pause, playSkipBack, square, arrowRedo, arrowUndo, volumeHigh, volumeLow, volumeMute } from 'ionicons/icons';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'mob-player-shared',
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
  templateUrl: './mob-player-shared.component.html',
  styleUrl: './mob-player-shared.component.scss',
})

export class MobPlayerSharedComponent extends PlayerClass implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('container', {read: ElementRef}) containerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('videoAzz', {read: ElementRef}) videoElement!: ElementRef;

  @Output() fullscreenClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exitPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _displayMode: 'portrait' | 'landscape' = 'portrait';
  private passwordAlert: HTMLIonAlertElement | undefined;
  private appStateSubscription:  Subscription | undefined = undefined;

  @Input() set mode(value: 'vod' | 'live') {
    this._mode = value;
  }

  @Input() set data(value: IPlayerData) {
    this._data = value;
  }

  @Input() set ready(ready: boolean) {
    this.setReady(ready);
  }

  @Input() set displayMode(mode: 'portrait' | 'landscape') {
    this._displayMode = mode;
    this.screenOrientationChanged(mode);
  }

  get displayMode(): 'portrait' | 'landscape' {
    return this._displayMode;
  }
  constructor(private alertCtrl: AlertController,
              protected alertService: AlertService,
              protected override router: Router,
              protected override translate: TranslateService,
              protected override formatTime: FormatHoursTimePipe,
              @Inject(DOCUMENT) protected override document: any,
              protected override http: HttpClient,
              //private utilsService: UtilsService,
              //private appService: AppService
            ) {
    super(formatTime, router, translate, document, http);
  }

  ngOnInit(): void {
    console.log('[MobPlayerSharedComponent] -> On init');
    addIcons({ browsers, chevronBack, ellipse, expand, play, pause, playSkipBack, arrowRedo, square, arrowUndo, volumeHigh, volumeLow, volumeMute });

    // this.appStateSubscription = this.appService.stateChange$.subscribe((isActive: boolean) => {
    //   if (!isActive) {
    //     if (!this.player?.paused) {
    //       this.fullScreenPressed();
    //       this.onPipScreenPressed();
    //     }
    //   } else {
    //     if(this.utilsService.playerPipState) {
    //       this.utilsService.playerPipState = false;
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroy();
    this.appStateSubscription?.unsubscribe();
    this.passwordAlert?.dismiss()
      .catch(console.error)
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.init(this.containerElement, this.videoElement);
  }

  protected override displayAlert(data: IAlertData): void {
    this.alertService.showAlert(data);
  }

  protected override emitExitPage(value: boolean): void {
    this.exitPage.emit(value);
  }

  protected showControls(): void {
    this.mouseIn();
  }

  protected changeVolume(event: any): void {
    this.volumePressed(event, event.detail.value);
  }

  protected override fullScreenPressed(): void {
    // if(this.utilsService.playerPipState) {
    //   this.utilsService.playerPipState = false;
    // }
    super.fullScreenPressed();
    this.fullscreenClicked.emit(this.playerControls.isFullscreen);
  }

  protected override showModal(): void {
    // if (this.displayModalPage === PlayerModals.passwordPrompt) {
    //   this.requestPassword()
    //     .catch(console.error);
    // }
  }

  protected override hideModal(): void {
    if( this.passwordAlert ) {
      this.passwordAlert.dismiss()
        .catch(console.error);
    }
  }

  async requestPassword(): Promise<void> {
    if (this.player) {
      this.destroyPlayer();
      this.player = null;
    }
    this._data.password = '';
    this.passwordAlert = await this.alertCtrl.create({
      header: this.translate.instant('player.protectedPasswordTitle'),
      message: this.translate.instant('player.passwordProtectedMessage'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('general.cancel'),
          role: 'cancel',
          handler: () => {
            this.destroyPlayer();
          },
        },
        {
          text: this.translate.instant('general.accept'),
          role: 'enter',
          handler: (event) => {
            this.setPassword(event.password);
          },
        }
      ],
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password'
        }
      ],
    });

    await this.passwordAlert.present();
  }

  private screenOrientationChanged(displayMode: 'landscape' | 'portrait'): void {
    if(displayMode === 'portrait' && this.playerControls.isFullscreen) {
      this.fullScreenPressed();
    }
  }

  protected onPipScreenPressed(): void {
    //this.utilsService.playerPipState = true;
    this.pipScreenPressed();
  }
}